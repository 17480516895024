<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Información de la Tarjeta</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          :loading="loading"
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <small class="font-weight-bold">Nombre</small>
              <h3>{{ card.name }}</h3>
            </v-col>

            <v-col v-if="card.nickname" cols="12" sm="6" md="6">
              <small class="font-weight-bold">Apodo</small>
              <h3>{{ card.nickname }}</h3>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <small class="font-weight-bold">Marca</small>
              <h3>
                <i
                  v-if="card.brand == 'VISA'"
                  class="fab fa-cc-visa"
                  style="color: #0057a0"
                ></i>
                <i
                  v-if="card.brand == 'MASTERCARD'"
                  class="fab fa-cc-mastercard"
                  style="color: #f79e1c"
                ></i>
                {{ card.brand }}
                <small style="font-weight: bold"
                  >( Termina en {{ card.endsIn }})</small
                >
              </h3>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <small class="font-weight-bold">Tipo</small>
              <h3>{{ card.type }}</h3>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <small class="font-weight-bold">Producto</small>
              <h3>{{ card.product }}</h3>
            </v-col>

            <v-col v-if="card.issuer" cols="12" sm="6" md="6">
              <small class="font-weight-bold">Proveedor</small>
              <h3>{{ card.issuer }}</h3>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <small class="font-weight-bold">Válida hasta</small>
              <h3>{{ card.validThru | filterDate }}</h3>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <small class="font-weight-bold">Fecha de creación</small>
              <h3>{{ card.createdAt | filterFullDate }}</h3>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "edit-exchange-rate",
  props: ["item"],
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      loading: true,
      card: {},
    };
  },
  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("MM/YYYY");
    },
    filterFullDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY hh:mm A");
    },
  },
  async mounted() {
    await this.$binding(
      "card",
      db
        .collection("users")
        .doc(this.item.userId)
        .collection("cards")
        .doc(this.item.orderData.orderPaymentToken)
    );
    this.loading = false;
  },

  methods: {},
};
</script>
